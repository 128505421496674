<template>
  <div class="shop-setting">
    <el-card class="basic-form">
      <div class="title">{{ $t('ji-chu-xin-xi') }}</div>
      <el-form :model="form" ref="form" label-width="100px">
        <el-form-item :label="$t('dian-pu-ming-cheng')" prop="Name">
          <el-input
            v-model="form.Name"
            :placeholder="$t('qing-shu-ru-dian-pu-ming-cheng-3')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('dian-pu-logo')" prop="Logo">
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            :action="uploadUrl"
            :headers="headers"
            name="file"
            :on-success="(file) => uploadSuccess(file, 'Logo')"
            :before-upload="beforeUpload"
          >
            <img v-if="form.Logo" :src="getBaseUrl(form.Logo)" class="avatar" />
            <i
              v-else
              class="el-icon-camera-solid"
              style="font-size: 30px; color: #aaa"
            ></i>
          </el-upload>
        </el-form-item>
        <el-form-item :label="$t('lian-xi-ren')" prop="Contact">
          <el-input
            v-model="form.Contact"
            :placeholder="$t('qing-shu-ru-lian-xi-ren-0')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('dian-pu-dian-hua')" prop="Phone">
          <el-input
            v-model="form.Phone"
            :placeholder="$t('qing-shu-ru-dian-pu-dian-hua-0')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('dian-pu-jian-jie')" prop="Intro">
          <el-input
            type="textarea"
            :rows="5"
            :show-word-limit="true"
            maxlength="200"
            v-model="form.Intro"
            :placeholder="$t('qing-shu-ru-dian-pu-jian-jie-0')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('jin-dian-huan-ying-yu')" prop="Welcome">
          <el-input
            type="textarea"
            :rows="5"
            :show-word-limit="true"
            maxlength="200"
            v-model="form.Welcome"
            :placeholder="$t('qing-shu-ru-jin-dian-huan-ying-yu-0')"
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="submitBasic">{{
            $t('bao-cun-1')
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card class="banner-form">
      <div class="title">{{ $t('heng-fu-she-zhi') }}</div>

      <div class="label">{{ $t('dian-pu-heng-fu-1-1920x300') }}</div>
      <el-upload
        class="banner-upload"
        :show-file-list="false"
        :action="uploadUrl"
        :headers="headers"
        name="file"
        :on-success="(file) => uploadSuccess(file, 'banner1')"
        :before-upload="beforeUpload"
      >
        <img
          v-if="form.banner1"
          :src="getBaseUrl(form.banner1)"
          class="avatar"
        />
        <i
          v-else
          class="el-icon-camera-solid"
          style="font-size: 30px; color: #aaa"
        ></i>
      </el-upload>

      <div class="label">{{ $t('dian-pu-heng-fu-2-1920x300') }}</div>
      <el-upload
        class="banner-upload"
        :show-file-list="false"
        :action="uploadUrl"
        :headers="headers"
        name="file"
        :on-success="(file) => uploadSuccess(file, 'banner2')"
        :before-upload="beforeUpload"
      >
        <img
          v-if="form.banner2"
          :src="getBaseUrl(form.banner2)"
          class="avatar"
        />
        <i
          v-else
          class="el-icon-camera-solid"
          style="font-size: 30px; color: #aaa"
        ></i>
      </el-upload>

      <div class="label">{{ $t('dian-pu-heng-fu-3-1920x300') }}</div>
      <el-upload
        class="banner-upload"
        :show-file-list="false"
        :action="uploadUrl"
        :headers="headers"
        name="file"
        :on-success="(file) => uploadSuccess(file, 'banner3')"
        :before-upload="beforeUpload"
      >
        <img
          v-if="form.banner3"
          :src="getBaseUrl(form.banner3)"
          class="avatar"
        />
        <i
          v-else
          class="el-icon-camera-solid"
          style="font-size: 30px; color: #aaa"
        ></i>
      </el-upload>

      <el-button type="primary" class="mt-15" @click="submitBanner">{{
        $t('bao-cun-1')
      }}</el-button>
    </el-card>

    <!-- <el-card class="social-form">
      <div class="title">{{ $t('she-jiao-mei-ti') }}</div>
      <el-form :model="socialForm" label-width="100px">
        <el-form-item :label="Facebook" prop="Facebook">
          <el-input
            v-model="socialForm.Facebook"
            :placeholder="$t('qing-shu-ru-facebook-lian-jie-di-zhi-0')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="Twitter" prop="Twitter">
          <el-input
            v-model="socialForm.Twitter"
            :placeholder="$t('qing-shu-ru-twitter-lian-jie-di-zhi-0')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="Google" prop="Google">
          <el-input
            v-model="socialForm.Google"
            :placeholder="$t('qing-shu-ru-google-lian-jie-di-zhi-0')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="YouTube" prop="Youtube">
          <el-input
            v-model="socialForm.Youtube"
            :placeholder="$t('qing-shu-ru-youtube-lian-jie-di-zhi-0')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="Instagram" prop="Instagram">
          <el-input
            v-model="socialForm.Instagram"
            :placeholder="$t('qing-shu-ru-instagram-lian-jie-di-zhi-0')"
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="submitSoical">{{
            $t('bao-cun-1')
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-card> -->

    <el-card class="user-form">
      <div class="title">{{ $t('ge-ren-xin-xi') }}</div>
      <el-form :model="userForm" label-width="100px">
        <el-form-item :label="$t('tou-xiang')" prop="avatar">
          <div class="flex-center-start">
            <div class="user-header big" :class="`header-${header}`"></div>
            <el-button
              class="right-btn"
              type="text"
              @click="headerDialog = true"
              >{{ $t('xiu-gai') }}</el-button
            >
          </div>
        </el-form-item>
        <el-form-item :label="$t('xing-ming-0')" prop="username">
          <el-input v-model="userForm.username" disabled>
            <template slot="suffix">
              <div
                class="flex-center"
                :class="isShopAuth ? 'success-info' : 'warning-info'"
              >
                <i class="el-icon-success" v-if="isShopAuth"></i>
                <i class="el-icon-time" v-else></i>
                {{ isShopAuth ? $t('yi-ren-zheng') : $t('dai-ren-zheng-0') }}
              </div>
            </template>
          </el-input>
          <el-button
            class="right-btn"
            type="text"
            @click="authDialog = true"
            v-if="!isShopAuth"
            >{{ $t('ren-zheng') }}</el-button
          >
        </el-form-item>
        <el-form-item :label="$t('shou-ji-hao')" prop="phone">
          <el-input v-model="userForm.phone" disabled>
            <template slot="suffix">
              <div class="error-info flex-center">
                <i class="el-icon-circle-close"></i>
                {{ $t('wei-she-zhi') }}
              </div>
            </template>
          </el-input>
          <el-button
            class="right-btn"
            type="text"
            @click="editPhoneDialog = true"
            >{{ $t('she-zhi-0') }}</el-button
          >
        </el-form-item>
        <el-form-item :label="$t('you-xiang-0')" prop="email">
          <el-input v-model="userForm.phone" disabled>
            <template slot="suffix">
              <div class="success-info flex-center">
                <i class="el-icon-success"></i>
                {{ $t('yi-ren-zheng') }}
              </div>
            </template>
          </el-input>
          <el-button
            type="text"
            class="right-btn"
            @click="editEmailDialog = true"
            >{{ $t('xiu-gai') }}</el-button
          >
        </el-form-item>
        <el-form-item :label="$t('zi-jin-mi-ma')" prop="safePassword">
          <el-button type="text" @click="editPassword('1')">{{
            $t('xiu-gai')
          }}</el-button>
        </el-form-item>
        <el-form-item :label="$t('deng-lu-mi-ma')" prop="password">
          <el-button type="text" @click="editPassword('2')">{{
            $t('xiu-gai')
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <el-dialog
      title="$t('tou-xiang-xuan-ze')"
      :visible.sync="headerDialog"
      width="460px"
      class="header-dialog"
    >
      <div class="header-list">
        <div
          class="user-header big"
          :class="`header-${i}`"
          v-for="i in 20"
          :key="i"
          @click="changeHeader(i)"
        >
          <i class="el-icon-success" v-if="avatar === i"></i>
        </div>
      </div>

      <el-button type="primary" class="submit-btn" @click="submitAvatar">{{
        $t('que-ding')
      }}</el-button>
    </el-dialog>

    <el-dialog
      :title="$t('shen-fen-ren-zheng')"
      :visible.sync="authDialog"
      width="600px"
      class="auth-dialog"
    >
      <div class="auth-status flex-column-center warning-info">
        <i class="el-icon-time"></i>
        {{ $t('dai-ren-zheng-0') }}
      </div>
      <el-form :model="authForm" ref="authForm">
        <el-form-item :label="$t('guo-ji')" prop="ShopCountry" required>
          <el-input
            v-model="authForm.ShopCountry"
            :placeholder="$t('qing-shu-ru-guo-ji')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('zhen-shi-xing-ming')"
          prop="RealName"
          required
        >
          <el-input
            v-model="authForm.RealName"
            :placeholder="$t('qing-shu-ru-zhen-shi-xing-ming-3')"
          />
        </el-form-item>
        <el-form-item
          :label="$t('zheng-jian-hu-zhao-hao-ma')"
          prop="IdCard"
          required
        >
          <el-input
            v-model="authForm.IdCard"
            :placeholder="$t('qing-shu-ru-zheng-jian-hu-zhao-hao-ma-3')"
          />
        </el-form-item>
        <el-form-item
          :label="$t('zheng-jian-zhao-hu-zhao-shang-chuan')"
          prop="cardImg"
          required
        >
          <div class="flex-center-start upload-line">
            <div class="upload-box">
              <el-upload
                class="card-upload"
                :show-file-list="false"
                :action="uploadUrl"
                :headers="headers"
                name="file"
                :on-success="(file) => uploadAuthSuccess(file, 'IdCardFront')"
                :before-upload="beforeUpload"
              >
                <img
                  v-if="authForm.IdCardFront"
                  :src="getBaseUrl(authForm.IdCardFront)"
                  class="avatar"
                />
                <i
                  v-else
                  class="el-icon-camera-solid"
                  style="font-size: 30px; color: #aaa"
                ></i>
              </el-upload>
              <span class="text">{{ $t('zheng-jian-zheng-mian') }}</span>
            </div>
            <div class="upload-box">
              <el-upload
                class="card-upload"
                :show-file-list="false"
                :action="uploadUrl"
                :headers="headers"
                name="file"
                :on-success="(file) => uploadAuthSuccess(file, 'IdCardBack')"
                :before-upload="beforeUpload"
              >
                <img
                  v-if="authForm.IdCardBack"
                  :src="getBaseUrl(authForm.IdCardBack)"
                  class="avatar"
                />
                <i
                  v-else
                  class="el-icon-camera-solid"
                  style="font-size: 30px; color: #aaa"
                ></i>
              </el-upload>
              <span class="text">{{ $t('zheng-jian-fan-mian') }}</span>
            </div>
          </div>
        </el-form-item>
        <el-form-item :label="$t('pai-she-shi-li')" prop="demo">
          <div class="flex-center-start demo-line">
            <img :src="demoUrl1" alt="" />
            <img :src="demoUrl2" alt="" />
          </div>
        </el-form-item>
      </el-form>
      <el-button type="primary" class="submit-btn" @click="submitAuth">{{
        $t('ti-jiao-shen-qing')
      }}</el-button>
    </el-dialog>

    <el-dialog
      :title="$t('xiu-gai-shou-ji')"
      :visible.sync="editPhoneDialog"
      width="460px"
      class="phone-dialog"
    >
      <el-form :model="phoneForm">
        <el-form-item :label="$t('shou-ji-hao')" prop="phone">
          <el-input
            class="phone-input"
            v-model="phoneForm.phone"
            :placeholder="$t('qing-shu-ru-shou-ji-hao-11')"
          >
            <template slot="prefix">
              <div class="flex-center-start phone-pre">
                <i class="el-icon-mobile-phone"></i>
                <el-dropdown
                  placement="bottom-start"
                  @command="chooseCountry"
                  class="phone-pre-dropdown"
                >
                  <span class="text flex-center">
                    {{ phoneForm.phonePre }}
                    <i class="el-icon-arrow-down"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <div class="phone-dropdown-item">
                      <el-input
                        v-model="keyword"
                        :placeholder="$t('sou-suo-4')"
                        @input="searchCountry"
                        @blur="searchCountry"
                      ></el-input>
                      <div
                        v-for="group in showCountryList"
                        :key="group.id"
                        ::label="group.title"
                      >
                        <el-dropdown-item
                          v-for="item in group.children"
                          :key="item.key"
                          :command="item.key"
                          >{{ item.title }}</el-dropdown-item
                        >
                      </div>
                    </div>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('yan-zheng-ma-0')" prop="code">
          <el-input
            v-model="phoneForm.code"
            :placeholder="$t('qing-shu-ru-yan-zheng-ma-2')"
          >
            <template slot="suffix">
              <el-button type="text">{{ $t('fa-song-0') }}</el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('deng-lu-mi-ma')" prop="password">
          <el-input
            :type="showPassword ? 'text' : 'password'"
            v-model="phoneForm.password"
            :placeholder="$t('qing-shu-ru-deng-lu-mi-ma-1')"
          >
            <template slot="suffix">
              <div
                class="flex-center img-suffix"
                @click="showPassword = !showPassword"
              >
                <i
                  class="el-icon-circle-close"
                  style="color: #999"
                  @click.stop="clear"
                ></i>
                <img :src="eyeUrl" alt="" v-if="!showPassword" />
                <img :src="eyeUrl2" alt="" v-else />
              </div>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" class="submit-btn" @click="submitEditPhone">{{
        $t('xiu-gai')
      }}</el-button>
    </el-dialog>

    <el-dialog
      :title="$t('xiu-gai-you-xiang')"
      :visible.sync="editEmailDialog"
      width="460px"
      class="phone-dialog"
    >
      <el-form :model="emailForm">
        <el-form-item :label="$t('you-xiang-hao-ma')" prop="email">
          <el-input
            v-model="emailForm.code"
            :placeholder="$t('qing-shu-ru-yan-zheng-ma-3')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('yan-zheng-ma-0')" prop="code">
          <el-input
            v-model="emailForm.code"
            :placeholder="$t('qing-shu-ru-yan-zheng-ma-4')"
          >
            <template slot="suffix">
              <el-button type="text">{{ $t('fa-song-0') }}</el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('deng-lu-mi-ma')" prop="password">
          <el-input
            :type="showPassword ? 'text' : 'password'"
            v-model="emailForm.password"
            :placeholder="$t('qing-shu-ru-deng-lu-mi-ma-2')"
          >
            <template slot="suffix">
              <div
                class="flex-center img-suffix"
                @click="showPassword = !showPassword"
              >
                <i
                  class="el-icon-circle-close"
                  style="color: #999"
                  @click="clear"
                ></i>
                <img :src="eyeUrl" alt="" v-if="!showPassword" />
                <img :src="eyeUrl2" alt="" v-else />
              </div>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" class="submit-btn" @click="submitEditEmail">{{
        $t('xiu-gai')
      }}</el-button>
    </el-dialog>

    <el-dialog
      :title="
        passwordFlag == '1'
          ? $t('zhong-zhi-zi-jin-mi-ma')
          : $t('zhong-zhi-deng-lu-mi-ma')
      "
      :visible.sync="editPasswordDialog"
      width="460px"
      class="phone-dialog"
    >
      <el-form :model="passwordForm" ref="passwordForm" :rules="formRule">
        <el-form-item
          :label="$t('jiu-mi-ma')"
          prop="OldPassword"
          v-if="isSet || passwordFlag == '2'"
        >
          <el-input
            :type="showPassword ? 'text' : 'password'"
            v-model="passwordForm.OldPassword"
            :placeholder="$t('qing-shu-ru-jiu-mi-ma-3')"
          >
            <template slot="suffix">
              <div
                class="flex-center img-suffix"
                @click="showPassword = !showPassword"
              >
                <i
                  class="el-icon-circle-close"
                  style="color: #999"
                  @click.stop="passwordForm.OldPassword = ''"
                ></i>
                <img :src="eyeUrl" alt="" v-if="!showPassword" />
                <img :src="eyeUrl2" alt="" v-else />
              </div>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('xin-mi-ma')" prop="Password">
          <el-input
            :type="showPassword2 ? 'text' : 'password'"
            v-model="passwordForm.Password"
            :placeholder="$t('qing-shu-ru-xin-mi-ma-3')"
          >
            <template slot="suffix">
              <div
                class="flex-center img-suffix"
                @click="showPassword2 = !showPassword2"
              >
                <i
                  class="el-icon-circle-close"
                  style="color: #999"
                  @click.stop="passwordForm.Password = ''"
                ></i>
                <img :src="eyeUrl" alt="" v-if="!showPassword2" />
                <img :src="eyeUrl2" alt="" v-else />
              </div>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('que-ren-mi-ma')" prop="PasswordConfirm">
          <el-input
            :type="showPassword3 ? 'text' : 'password'"
            v-model="passwordForm.PasswordConfirm"
            :placeholder="$t('qing-zai-ci-shu-ru-mi-ma-1')"
          >
            <template slot="suffix">
              <div
                class="flex-center img-suffix"
                @click="showPassword3 = !showPassword3"
              >
                <i
                  class="el-icon-circle-close"
                  style="color: #999"
                  @click.stop="passwordForm.PasswordConfirm = ''"
                ></i>
                <img :src="eyeUrl" alt="" v-if="!showPassword3" />
                <img :src="eyeUrl2" alt="" v-else />
              </div>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        class="submit-btn"
        @click="submitEditPassword"
        >{{ $t('xiu-gai') }}</el-button
      >
    </el-dialog>
  </div>
</template>
<script>
import { getToken } from '@/utils/auth'
import {
  editPassword,
  editSafePassword,
  setSafePassword,
  checkSafePassword
} from '@/api/user'
import { shopSetting, shopInfo, shopAuth, getShopAuthStatus } from '@/api/shop'
import { getBaseUrl } from '@/utils/common'
export default {
  data() {
    return {
      demoUrl1: require('@/assets/imgs/upload-demo1.png'),
      demoUrl2: require('@/assets/imgs/upload-demo2.png'),
      eyeUrl: require('@/assets/imgs/icon-eye-black.png'),
      eyeUrl2: require('@/assets/imgs/icon-eye2-black.png'),
      stepIndex: 0,
      form: {
        Name: '',
        Logo: '',
        Phone: '',
        banner1: '',
        banner2: '',
        banner3: ''
      },
      headers: {},
      socialForm: {},
      userForm: {},
      authForm: {},
      phoneForm: {
        phonePre: '+86'
      },
      emailForm: {},
      passwordForm: {
        OldPassword: '',
        Password: '',
        PasswordConfirm: ''
      },
      avatar: 1,
      header: 1,
      headerDialog: false,
      authDialog: false,
      editPhoneDialog: false,
      editEmailDialog: false,
      showPassword: false,
      showPassword2: false,
      showPassword3: false,
      editPasswordDialog: false,
      passwordFlag: '',
      keyword: '',
      showCountryList: [],
      formRule: {
        OldPassword: [
          { message: this.$t('qing-shu-ru-jiu-mi-ma-0'), required: true }
        ],
        Password: [
          { message: this.$t('qing-shu-ru-xin-mi-ma-0'), required: true }
        ],
        PasswordConfirm: [
          {
            required: true,
            validator: this.checkPassword
          }
        ]
      },
      isSet: false
    }
  },
  computed: {
    countryList() {
      return this.$store.state.countryList
    },
    isShopAuth() {
      return this.$store.state.isShopAuth
    },
    imgType() {
      return this.$store.state.imgType
    },
    uploadUrl() {
      return this.$store.state.uploadUrl
    },
    FirstSetup() {
      return this.$store.state.uploadUrl
    }
  },
  mounted() {
    let type = this.$route.query.type
    if (type == 'auth') {
      this.initAuth()
      // 认证
      this.authDialog = true
    }
    this.showCountryList = [].concat(this.countryList)
    this.headers = {
      Token: getToken()
    }
    this.init()
    this.initCheck()
  },
  methods: {
    getBaseUrl,
    initAuth() {
      getShopAuthStatus().then((res) => {
        if (res.data) {
          this.$toast(
            this.$t('nin-yi-ti-jiao-ren-zheng-shen-qing-qing-nai-xin-deng-dai')
          )
        }
      })
    },
    initCheck() {
      checkSafePassword().then((res) => {
        this.isSet = res.data
      })
    },
    init() {
      shopInfo().then((res) => {
        let data = res.data
        this.shopInfo = res.data
        this.$store.state.isShopAuth = data.IsAuth === 1
        this.$store.state.FirstSetup = data.FirstSetup === 1
        this.form = {
          Name: data.Name,
          Logo: data.Logo,
          Phone: data.Phone,
          Intro: data.Intro,
          Welcome: data.Welcome,
          Contact: data.Contact,
          banner1: data.Banner,
          banner2: data.Banner1,
          banner3: data.Banner2
        }
        this.socialForm = {
          Facebook: data.Facebook,
          Twitter: data.Twitter,
          Youtube: data.Youtube,
          Instagram: data.Instagram,
          Google: data.Google
        }
      })
    },
    uploadSuccess(res, type) {
      this.form[type] = res.data.FileName
    },
    uploadAuthSuccess(res, type) {
      this.authForm[type] = res.data.FileName
      this.authForm.cardImg =
        this.authForm.IdCardFront && this.authForm.IdCardBack ? '1' : ''
    },
    beforeUpload(file) {
      let name = file.name
      let type = name.substring(name.indexOf('.') + 1)
      if (this.imgType.indexOf(type) === -1) {
        this.$message.error(this.$t('zhi-neng-shang-chuan-tu-pian'))
        return false
      }
      return true
    },
    changeHeader(i) {
      this.avatar = i
    },
    submitAvatar() {
      this.header = this.avatar
      this.headerDialog = false
    },
    submitAuth() {
      this.$refs.authForm.validate((vali) => {
        if (vali) {
          shopAuth({
            RealName: this.authForm.RealName,
            IdCard: this.authForm.IdCard,
            IdCardFront: this.authForm.IdCardFront,
            IdCardBack: this.authForm.IdCardBack,
            ShopCountry: this.authForm.ShopCountry
          }).then((res) => {
            if (res.code == 0) {
              this.$message.success(this.$t('she-zhi-cheng-gong'))
              this.authDialog = false
              this.init()
            } else {
              this.$message.warning(res.msg)
            }
          })
        }
      })
    },
    submitEditPhone() {
      this.editPhoneDialog = false
    },
    submitEditEmail() {
      this.editEmailDialog = false
    },
    editPassword(type) {
      this.passwordFlag = type
      this.editPasswordDialog = true
    },
    handleChange(value) {
      this.phoneForm.phonePre = '+86'
    },
    searchCountry() {
      if (!this.keyword) {
        this.showCountryList = [].concat(this.countryList)
      } else {
        let allList = JSON.parse(JSON.stringify(this.countryList))
        this.showCountryList = allList.map((v) => {
          v.children = v.children.filter((val) => {
            return (
              val.key.indexOf(this.keyword) > -1 ||
              val.title.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
            )
          })
          return v
        })
      }
    },
    chooseCountry(data) {
      this.phoneForm.phonePre = data
    },
    clear() {
      this.phoneForm.password = ''
    },
    submitBasic() {
      shopSetting({
        ...this.shopInfo,
        Name: this.form.Name,
        Logo: this.form.Logo,
        Contact: this.form.Contact,
        Contact: this.form.Contact,
        Intro: this.form.Intro,
        Welcome: this.form.Welcome,
        Phone: this.form.Phone
      }).then((res) => {
        if (res.code == 0) {
          this.$message.success(this.$t('xiu-gai-cheng-gong'))
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    submitBanner() {
      if (!this.form.banner1 && !this.form.banner2 && !this.form.banner3) {
        this.$message.warning(this.$t('qing-shang-chuan-heng-fu-tu-pian'))
        return
      }
      shopSetting({
        ...this.shopInfo,
        Banner: this.form.banner1,
        Banner1: this.form.banner2,
        Banner2: this.form.banner3
      }).then((res) => {
        if (res.code == 0) {
          this.$message.success(this.$t('xiu-gai-cheng-gong'))
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    submitSoical() {
      shopSetting({
        ...this.shopInfo,
        Facebook: this.socialForm.Facebook,
        Twitter: this.socialForm.Twitter,
        Youtube: this.socialForm.Youtube,
        Instagram: this.socialForm.Instagram,
        Google: this.socialForm.Google
      }).then((res) => {
        if (res.code == 0) {
          this.$message.success(this.$t('xiu-gai-cheng-gong'))
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    submitEditPassword() {
      this.$refs.passwordForm.validate((vali) => {
        if (vali) {
          if (this.passwordFlag == 2) {
            editPassword({
              OldPassword: this.passwordForm.OldPassword,
              Password: this.passwordForm.Password,
              PasswordConfirm: this.passwordForm.PasswordConfirm
            }).then((res) => {
              if (res.code == 0) {
                this.$message.success(this.$t('xiu-gai-cheng-gong'))
                this.$refs.passwordForm.resetFields()
                this.editPasswordDialog = false
              } else {
                this.$message.warning(res.msg)
              }
            })
          } else {
            if (this.isSet) {
              editSafePassword({
                OldPassword: this.passwordForm.OldPassword,
                Password: this.passwordForm.Password,
                PasswordConfirm: this.passwordForm.PasswordConfirm
              }).then((res) => {
                if (res.code == 0) {
                  this.$message.success(this.$t('xiu-gai-cheng-gong'))
                  this.$refs.passwordForm.resetFields()
                  this.editPasswordDialog = false
                } else {
                  this.$message.warning(res.msg)
                }
              })
            } else {
              setSafePassword({
                SafePassword: this.passwordForm.Password,
                SafePasswordConfirm: this.passwordForm.PasswordConfirm
              }).then((res) => {
                if (res.code == 0) {
                  this.$message.success(this.$t('she-zhi-cheng-gong'))
                  this.$refs.passwordForm.resetFields()
                  this.editPasswordDialog = false
                  this.isSet = true
                } else {
                  this.$message.warning(res.msg)
                }
              })
            }
          }
        }
      })
    },
    checkPassword(rule, value, callback) {
      if (!value) {
        return callback(this.$t('qing-que-ren-mi-ma-0'))
      } else if (
        this.passwordForm.Password != this.passwordForm.PasswordConfirm
      ) {
        callback(this.$t('liang-ci-shu-ru-de-mi-ma-bu-yi-zhi'))
      } else {
        callback()
      }
    }
  }
}
</script>